<template>
  <div>
    <div class="van-main">
      <van-form ref="company" label-width="120px" :loading="loading.detail" class="van-form-margin">
        <p class="van-block-title">
          请上传营业执照照片
        </p>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="企业名称：" :value="utils.isEffectiveCommon(appForm.orgName)" />
            <van-cell title="统一社会信用代码：" :value="utils.isEffectiveCommon(appForm.orgNo)" />
            <van-cell title="营业执照图片：">
              <upload-file rectangle-style max-count="1" upload-text="上传营业执照图片" :query="query" :before-read="beforeUpload" @fileUploadSuccess="(response, file, fileList) => fileUploadSuccess('orgLicenseImgs', response, file, fileList)" />
            </van-cell>
            <van-field
              v-model="appForm.orgContactPhone"
              name="orgContactPhone"
              label="企业联系电话："
              required
              placeholder="请输入企业联系电话"
              :rules="rules.orgContactPhone"
            />
          </van-cell-group>
        </div>
        <p class="van-block-title">
          请填写企业法定代表人信息
        </p>
        <div class="van-common-detail">
          <van-cell-group>
            <van-field
              v-model="appForm.businessName"
              name="businessName"
              label="姓名："
              required
              placeholder="请输入姓名"
              :rules="rules.businessName"
            />
            <van-field
              readonly
              clickable
              required
              name="picker"
              :value="appForm.businessSnType?utils.statusFormat(appForm.businessSnType,'typeCertificateType'):''"
              label="证件类型"
              :rules="rules.businessSnType"
              placeholder="请选择证件类型"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="constants.typeCertificateType"
                value-key="label"
                :default-index="_.toNumber(appForm.businessSnType)"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
            <van-field
              v-model="appForm.businessSn"
              name="businessSn"
              label="证件号码："
              required
              placeholder="请输入证件号码"
              :rules="rules.businessSn"
            />
            <van-field
              v-model="appForm.businessEmail"
              name="businessEmail"
              label="电子邮箱："
              required
              placeholder="请输入电子邮箱"
              :rules="rules.businessEmail"
            />
          </van-cell-group>
          <div class="van-file">
            <upload-file rectangle-style max-count="1" upload-text="上传身份证国徽面" :query="query" :before-read="beforeUpload" @fileUploadSuccess="(response, file, fileList) => fileUploadSuccess('businessSnPreImgs', response, file, fileList)" />
          </div>
          <div class="van-file">
            <upload-file rectangle-style max-count="1" upload-text="上传身份证头像面" :query="query" :before-read="beforeUpload" @fileUploadSuccess="(response, file, fileList) => fileUploadSuccess('businessSnBackImgs', response, file, fileList)" />
          </div>
        </div>
        <div class="button-fixed">
          <p class="button-fixed-p">
            *温馨提示：请核对填写内容无误后再进行提交。
          </p>
          <van-button block type="info" native-type="submit" :loading="loading.submit" @click="confirm">
            提 交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Form, Field, Calendar, Uploader, Picker, Popup, Sticky, Toast } from 'vant'
import UploadFile from '@/components/upload-file'
export default {
  components: {
    UploadFile,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Form.name]: Form,
    [Field.name]: Field,
    [Calendar.name]: Calendar,
    [Uploader.name]: Uploader,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Sticky.name]: Sticky
  },
  data () {
    return {
      enterpriseId: this.$route.query.enterpriseId,
      fileList: [],
      query: {
        module: 'SIGN_UP',
        businessId: this.$route.query.enterpriseId
      },
      appForm: {
        orgName: '',
        orgNo: '',
        orgContactPhone: '',
        businessName: '',
        businessSnType: '',
        businessSn: '',
        businessEmail: '',
        orgLicenseImgs: '',
        businessSnPreImgs: '',
        businessSnBackImgs: ''
      },
      showPicker: false,
      loading: {
        detail: false,
        submit: false
      },
      finished: false,
      refreshing: false,
      rules: {
        orgContactPhone: [
          { required: true, message: '请输入企业联系电话' },
          { pattern: this.constants.rules20, message: this.constants.rulesToolkitsNumber20 }
        ],
        businessName: [
          { required: true, message: '请输入姓名' },
          { pattern: this.constants.rulesExclude50, message: this.constants.rulesToolkit50 }
        ],
        businessSnType: [
          { required: true, message: '请选择证件类型', trigger: 'onChange' }
        ],
        businessSn: [
          { required: true, message: '请输入证件号码' },
          { pattern: this.constants.rulesNumber, message: this.constants.rulesToolkitsNumber },
          { pattern: this.constants.rulesExclude20, message: this.constants.rulesToolkit20 }
        ],
        businessEmail: [
          { required: true, message: '请输入电子邮箱' },
          { pattern: this.constants.email, message: this.constants.emailToolkit },
          { pattern: this.constants.rulesExclude50, message: this.constants.rulesToolkit50 }
        ]
      }
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    // 上传大小
    beforeUpload (file) {
      const isLt = file.size / 1024 / 1024 < 10
      if (!isLt) {
        Toast.fail('上传文件不能大于10M')
        return isLt
      } else {
        return isLt
      }
    },
    // 上传成功
    fileUploadSuccess (type, response, file, fileList) {
      this.appForm[type] = response.fileUrl
    },
    onConfirm (value) {
      this.appForm.businessSnType = value.value
      this.showPicker = false
    },
    // 获取详情
    getDetail () {
      this.loading.detail = true
      this.api.base.enterpriseAuth(this.enterpriseId).then(result => {
        const data = this._.cloneDeep(result.data.value)
        this.appForm = this._.assign(this.appForm, data)
      }).finally(() => {
        this.loading.detail = false
      })
    },
    confirm () {
      if (!this.appForm.orgLicenseImgs) {
        Toast.fail('请上传营业执照图片')
        return false
      }
      if (!this.appForm.businessSnPreImgs) {
        Toast.fail('请上传身份证国徽面')
        return false
      }
      if (!this.appForm.businessSnBackImgs) {
        Toast.fail('请上传身份证头像面')
        return false
      }
      this.$refs.company.validate().then(() => {
        this.enterpriseFourFactorVerify().then(result => {
          this.loading.submit = true
          const data = this._.cloneDeep(this.appForm)
          this.api.base.submitAuthItem(data).then(result => {
            // const data = result.data.data
            Toast.success(result.data.message || '提交成功')
            this.$router.push({ path: '/personal/information', query: { enterpriseId: this.enterpriseId } })
          }).finally(() => {
            this.loading.submit = false
          })
        })
      })
    },
    enterpriseFourFactorVerify () {
      return new Promise((resolve, reject) => {
        const { businessName: name, orgName: entName, businessSn: idcard, orgNo: creditcode } = this._.cloneDeep(this.appForm)
        this.api.base.enterpriseFourFactorVerify({ name, entName, idcard, creditcode }).then(result => {
          if (result.data.success) {
            resolve(result.data.value)
          }
        }).catch((e) => {
          reject(e)
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
</style>
