var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "van-main" },
      [
        _c(
          "van-form",
          {
            ref: "company",
            staticClass: "van-form-margin",
            attrs: { "label-width": "120px", loading: _vm.loading.detail }
          },
          [
            _c("p", { staticClass: "van-block-title" }, [
              _vm._v(" 请上传营业执照照片 ")
            ]),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-cell", {
                      attrs: {
                        title: "企业名称：",
                        value: _vm.utils.isEffectiveCommon(_vm.appForm.orgName)
                      }
                    }),
                    _c("van-cell", {
                      attrs: {
                        title: "统一社会信用代码：",
                        value: _vm.utils.isEffectiveCommon(_vm.appForm.orgNo)
                      }
                    }),
                    _c(
                      "van-cell",
                      { attrs: { title: "营业执照图片：" } },
                      [
                        _c("upload-file", {
                          attrs: {
                            "rectangle-style": "",
                            "max-count": "1",
                            "upload-text": "上传营业执照图片",
                            query: _vm.query,
                            "before-read": _vm.beforeUpload
                          },
                          on: {
                            fileUploadSuccess: function(
                              response,
                              file,
                              fileList
                            ) {
                              return _vm.fileUploadSuccess(
                                "orgLicenseImgs",
                                response,
                                file,
                                fileList
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("van-field", {
                      attrs: {
                        name: "orgContactPhone",
                        label: "企业联系电话：",
                        required: "",
                        placeholder: "请输入企业联系电话",
                        rules: _vm.rules.orgContactPhone
                      },
                      model: {
                        value: _vm.appForm.orgContactPhone,
                        callback: function($$v) {
                          _vm.$set(_vm.appForm, "orgContactPhone", $$v)
                        },
                        expression: "appForm.orgContactPhone"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("p", { staticClass: "van-block-title" }, [
              _vm._v(" 请填写企业法定代表人信息 ")
            ]),
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c(
                  "van-cell-group",
                  [
                    _c("van-field", {
                      attrs: {
                        name: "businessName",
                        label: "姓名：",
                        required: "",
                        placeholder: "请输入姓名",
                        rules: _vm.rules.businessName
                      },
                      model: {
                        value: _vm.appForm.businessName,
                        callback: function($$v) {
                          _vm.$set(_vm.appForm, "businessName", $$v)
                        },
                        expression: "appForm.businessName"
                      }
                    }),
                    _c("van-field", {
                      attrs: {
                        readonly: "",
                        clickable: "",
                        required: "",
                        name: "picker",
                        value: _vm.appForm.businessSnType
                          ? _vm.utils.statusFormat(
                              _vm.appForm.businessSnType,
                              "typeCertificateType"
                            )
                          : "",
                        label: "证件类型",
                        rules: _vm.rules.businessSnType,
                        placeholder: "请选择证件类型"
                      },
                      on: {
                        click: function($event) {
                          _vm.showPicker = true
                        }
                      }
                    }),
                    _c(
                      "van-popup",
                      {
                        attrs: { position: "bottom" },
                        model: {
                          value: _vm.showPicker,
                          callback: function($$v) {
                            _vm.showPicker = $$v
                          },
                          expression: "showPicker"
                        }
                      },
                      [
                        _c("van-picker", {
                          attrs: {
                            "show-toolbar": "",
                            columns: _vm.constants.typeCertificateType,
                            "value-key": "label",
                            "default-index": _vm._.toNumber(
                              _vm.appForm.businessSnType
                            )
                          },
                          on: {
                            confirm: _vm.onConfirm,
                            cancel: function($event) {
                              _vm.showPicker = false
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("van-field", {
                      attrs: {
                        name: "businessSn",
                        label: "证件号码：",
                        required: "",
                        placeholder: "请输入证件号码",
                        rules: _vm.rules.businessSn
                      },
                      model: {
                        value: _vm.appForm.businessSn,
                        callback: function($$v) {
                          _vm.$set(_vm.appForm, "businessSn", $$v)
                        },
                        expression: "appForm.businessSn"
                      }
                    }),
                    _c("van-field", {
                      attrs: {
                        name: "businessEmail",
                        label: "电子邮箱：",
                        required: "",
                        placeholder: "请输入电子邮箱",
                        rules: _vm.rules.businessEmail
                      },
                      model: {
                        value: _vm.appForm.businessEmail,
                        callback: function($$v) {
                          _vm.$set(_vm.appForm, "businessEmail", $$v)
                        },
                        expression: "appForm.businessEmail"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "van-file" },
                  [
                    _c("upload-file", {
                      attrs: {
                        "rectangle-style": "",
                        "max-count": "1",
                        "upload-text": "上传身份证国徽面",
                        query: _vm.query,
                        "before-read": _vm.beforeUpload
                      },
                      on: {
                        fileUploadSuccess: function(response, file, fileList) {
                          return _vm.fileUploadSuccess(
                            "businessSnPreImgs",
                            response,
                            file,
                            fileList
                          )
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "van-file" },
                  [
                    _c("upload-file", {
                      attrs: {
                        "rectangle-style": "",
                        "max-count": "1",
                        "upload-text": "上传身份证头像面",
                        query: _vm.query,
                        "before-read": _vm.beforeUpload
                      },
                      on: {
                        fileUploadSuccess: function(response, file, fileList) {
                          return _vm.fileUploadSuccess(
                            "businessSnBackImgs",
                            response,
                            file,
                            fileList
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "button-fixed" },
              [
                _c("p", { staticClass: "button-fixed-p" }, [
                  _vm._v(" *温馨提示：请核对填写内容无误后再进行提交。 ")
                ]),
                _c(
                  "van-button",
                  {
                    attrs: {
                      block: "",
                      type: "info",
                      "native-type": "submit",
                      loading: _vm.loading.submit
                    },
                    on: { click: _vm.confirm }
                  },
                  [_vm._v(" 提 交 ")]
                )
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }